import React from 'react';
import { useDispatch } from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';

import validateAccount from '../Api/ValidateAccount'

import {useSnackbar} from "notistack";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useValidateAccount(onSuccessful, onFailed) {
    const query = useQuery();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (query.get('token')) {
            setIsLoading(true);
            validateAccount(query.get('token'), (response) => {
                if (response.data) {
                    enqueueSnackbar('Votre compte a bien été validé. Vous pouvez désormais vous connecter.', {variant: 'success'});
                    navigate("/");
                    if (onSuccessful) onSuccessful();
                }
            }, error => {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
                if (onFailed) onFailed();
            }, () => {
                setIsLoading(false);
            })
        }
    }, [query]);

    return [isLoading];
}