import React from 'react';
import Drawer from "../Component/Drawer";

export function useBurgerMenu() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen(open);
    };

    const component = <Drawer toggleDrawer={toggleDrawer} open={open} />
    return [component, () => {
        toggleDrawer(!open)();
    }];
}