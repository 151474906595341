import { createSlice } from '@reduxjs/toolkit'

export const me = createSlice({
    name: 'me',
    initialState: {
        user: null,
        token: null,
    },
    reducers: {
        setToken: (state, {type, payload}) => {
            state.token = payload;
        },
        unsetToken: (state) => {
            state.token = null;
        },
        setMe: (state, {type, payload}) => {
            state.user = payload;
        },
        unsetMe: (state) => {
            state.user = null;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setToken, unsetToken, setMe, unsetMe, logout } = me.actions

export const { getToken, getMe } = {
    getToken: (state) => state.me.token,
    getMe: (state) => state.me.user
};

export default me.reducer