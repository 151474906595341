import { configureStore } from '@reduxjs/toolkit';
import { persistCombineReducers } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';

import me from '../feature/Login/Slice/me';
import rightDrawerOpen from '../feature/BurgerMenu/Slice/rightAction';
import dashboards from '../feature/Dashboard/Slice/dashboards';

const _persistedReducer = persistCombineReducers(
    {
        key: 'root',
        storage,
    },
    {
        me,
        dashboards,
        rightDrawerOpen
    }
);

export default configureStore({
    reducer: _persistedReducer,
    middleware: []
})