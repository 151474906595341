import React from "react";
import { styled } from '@mui/material/styles';

import MuiDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useBurgerMenu} from "../../BurgerMenu/Hook/useBurgerMenu";
import {getRightDrawerOpen, setDisplayIcon, setRightDrawerOpen} from "../../BurgerMenu/Slice/rightAction";
import {useDispatch, useSelector} from "react-redux";

const drawerWidth = 290;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Screen({widgets, editMode, isDraggingElement, setDeleting, isLoading}) {
    const anchor = 'right';

    const dispatch = useDispatch();
    const mobileOpen = useSelector(getRightDrawerOpen);

    const [hover, setHover] = React.useState(false);

    const onMouseEnter = () => {
        setHover(true);
        setDeleting(true);
    }

    const onMouseLeave = () => {
        setHover(false);
        setDeleting(false);
    }

    const drawerContent = <div>
        <Divider style={{marginBottom: 10}}/>
        <List style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {widgets}
        </List>
        {isDraggingElement && <Button
            style={{position: 'fixed', top: 0, right: 0, width: drawerWidth, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: hover ? '#000000E0' : '#000000D0'}}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <DeleteOutlineIcon fontSize={"large"} color={"primary"} />
        </Button>}
    </div>;
    return <Drawer
        anchor={anchor}
        variant={"persistent"}
        open={editMode}
        onOpen={() => null}
        onClose={() => null}
        sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
    >
        {drawerContent}
    </Drawer>;
}

export default Screen;
