import React from "react";

import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import LoadingButton from "../../../component/LoadingButton";

const Component = ({styles, isLoading, onValidate, onResetPassword}) => {

    const [errors, setErrors] = React.useState([]);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!username) {
            newErrors.username = "Ce champ est obligatoire.";
        }

        if (!password) {
            newErrors.password = "Ce champ est obligatoire.";
        }

        setErrors(Object.assign({}, errors, newErrors));
        if (Object.keys(newErrors).length === 0) {
            onValidate(username, password);
        }
    }

    const handleChangeUsername = (e) => {
        setErrors(Object.assign({}, errors, {username: null}));
        setUsername(e.target.value)
    }

    const handleChangePassword = (e) => {
        setErrors(Object.assign({}, errors, {password: null}));
        setPassword(e.target.value)
    }


    return <form onSubmit={onSubmit}>
        <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
            <Typography variant={"h4"} sx={styles.title}>Bienvenue</Typography>
            <Grid item sx={styles.fieldWrapper}>
                <TextField
                    variant={"outlined"}
                    value={username}
                    onChange={handleChangeUsername}
                    label={"Adresse e-mail"}
                    sx={styles.field}
                    error={!!errors.username}
                    helperText={errors.username} />
            </Grid>
            <Grid item sx={styles.fieldWrapper}>
                <TextField
                    variant={"outlined"}
                    type={"password"}
                    value={password}
                    onChange={handleChangePassword}
                    label={"Mot de passe"}
                    sx={styles.field}
                    error={!!errors.password}
                    helperText={errors.password} />
            </Grid>
            <Grid item>
                <LoadingButton isLoading={isLoading} type="submit" variant={"contained"} color={"primary"} sx={styles.submitButton}>
                    Se connecter
                </LoadingButton>
            </Grid>
            {/*{onResetPassword && <Grid item>*/}
            {/*    <Link href={"/mot-de-passe-oublié"}>*/}
            {/*        <Typography color={"primary"}>*/}
            {/*            Mot de passe oublié*/}
            {/*        </Typography>*/}
            {/*    </Link>*/}
            {/*</Grid>}*/}
            <Grid item>
                <Link href={"/register"}>
                    <Typography color={"primary"}>
                        Je n'ai pas de compte
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    </form>;
};

export default Component;