import React from 'react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import '../node_modules/leaflet/dist/leaflet.css';
import '../node_modules/react-leaflet-markercluster/dist/styles.min.css';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import store from "./reducers"
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { persistStore } from 'reduxjs-toolkit-persist';
import Login from "./screen/Login";
import Main from "./screen/Main";
import MainTheme from "./theme/Main";
import { SnackbarProvider } from 'notistack';
import {ThemeProvider} from "@mui/system";
import Dashboard from "./screen/Dashboard";
import Settings from "./screen/Settings";
import Account from "./screen/Account";
import Register from "./screen/Register";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import './index.css';
import CssBaseline from "@mui/material/CssBaseline";

import '@fontsource/ibm-plex-sans';
import '@fontsource/space-grotesk';

let persistor = persistStore(store);
document.title="DataOfficer - Dashboards"
ReactDOM.render(
   
  <React.StrictMode>
      <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
          }}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <ThemeProvider theme={MainTheme}>
                      <CssBaseline />
                      <DndProvider options={HTML5toTouch}>
                          <BrowserRouter forceRefresh={true} >
                              <Routes>
                                <Route path={"/"} element={<Login />} />
                                <Route path={"/register"} element={<Register />} />
                                <Route path={"/validate_account"} element={<Login />} />
                                <Route path="/app" element={<Main />}>
                                    <Route path="/app/dashboard/:id" element={<Dashboard />} />
                                    <Route path="/app/settings" element={<Settings />} />
                                    <Route path="/app/account" element={<Account />} />
                                </Route>
                              </Routes>
                          </BrowserRouter>
                      </DndProvider>
                  </ThemeProvider>
              </PersistGate>
          </Provider>
      </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
