import React from "react";

import Container from "@mui/material/Container";

function Screen() {

    return <Container maxWidth={"xs"}>

    </Container>;
}

export default Screen;
