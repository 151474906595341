import { createTheme } from '@mui/material/styles';


const FALLBACK_FONT = ", Roboto, sans-serif";
const TITLE_FONT = "Space Grotesk" + FALLBACK_FONT;
const BASE_FONT = "IBM Plex Sans" + FALLBACK_FONT;

let theme = createTheme({
    palette: {
        background: {
            default: '#EFEFEF'
        },
        primary: {
            main: '#F44336',
            alternate: '#22211E',
            contrastText: 'white',
        },
        secondary: {
            main: '#22211E',
            light: 'white',
            contrastText: 'white',
        },
        text: {
            disabled: {
                color: '#22211E'
            }
        }
    },
    typography: {
        allVariants: {
            color: '#22211E'
        },
        fontFamily: BASE_FONT,
        h1: { fontFamily: TITLE_FONT },
        h2: { fontFamily: TITLE_FONT },
        h3: { fontFamily: TITLE_FONT },
        h4: { fontFamily: TITLE_FONT },
        h5: { fontFamily: TITLE_FONT },
        h6: { fontFamily: TITLE_FONT },
    }
});

export default theme;