import React from "react";

import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {getMe} from "../../Login/Slice/me";
import CardMedia from "@mui/material/CardMedia";
import DashboardIcon from "@mui/icons-material/Dashboard";


function Component({open, setOpen, onValidate, dashboard, onClickDelete}) {
    const [name, setName] = React.useState("");
    const [defaultDash, setDefaultDash] = React.useState(false);
    const [icon, setIcon] = React.useState(-1);
    const me = useSelector(getMe);

    React.useEffect(() => {
        if (dashboard) {
            setName(dashboard.name);
            setDefaultDash(dashboard.defaultDashboard);
            setIcon(dashboard.logoId);
        }
    }, [dashboard]);

    const handleClickIcon = (id) => {
        if (!dashboard || dashboard.deletable) {
            setIcon(id);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleValidate = () => {
        onValidate(name, defaultDash, icon);
    }

    return <Dialog
        open={open}>
        <DialogTitle>{dashboard ? "Modifier" : "Créer"} un dashboard</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nom du dashboard"
                type="text"
                fullWidth
                variant="outlined"
                value={name}
                disabled={dashboard && !dashboard.deletable}
                onChange={e => setName(e.target.value)}
            />
            <Grid container>
                <Button variant={"outlined"} color={icon === -1 ? "primary" : "secondary"} onClick={() => handleClickIcon(-1)}>
                    <DashboardIcon color={"secondary"}/>
                </Button>
                {me && me.availableLogos.map((logo) => (
                    <Button variant={"outlined"} color={icon === logo.box ? "primary" : "secondary"} onClick={() => handleClickIcon(logo.box)}>
                        <CardMedia style={{display: 'flex'}}>
                            <img style={{width: 24, height: 24}} src={"data:image/svg+xml;base64," + logo.logo} />
                        </CardMedia>
                    </Button>
                ))}
            </Grid>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={defaultDash}
                    onChange={e => setDefaultDash(e.target.value)}/>} label={"Dashboard par défaut"} />
            </FormGroup>

        </DialogContent>
        <DialogActions style={{justifyContent: "space-between"}}>
            <Button variant={"outlined"} color={"secondary"} onClick={handleClose}>Annuler</Button>
            <Button variant={"outlined"} color={"primary"} onClick={handleValidate}>Valider</Button>
        </DialogActions>
        {onClickDelete && dashboard && dashboard.deletable && <DialogActions>
            <Button onClick={() => onClickDelete(dashboard)} variant={"contained"} style={{width: '100%'}}>Supprimer le dashboard</Button>
        </DialogActions>}
    </Dialog>;
}

export default Component;
