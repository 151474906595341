import React from "react";
import {makeStyles} from "@mui/material";

import Container from "@mui/material/Container";
import {useBoxesList} from "../feature/Box/Hook/useBoxesList";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from '@mui/icons-material/Delete';
import {useAddBoxDialog} from "../feature/Box/Hook/useAddBoxDialog";
import {useRemoveBox} from "../feature/Box/Hook/useRemoveBox";
import TextField from "@mui/material/TextField";
import {useDashboardList} from "../feature/Dashboard/Hook/useDashboardList";

function Screen() {
    const [dashboards, loadDashboards] = useDashboardList();
    const [boxes, triggerBoxesLoad, isLoading] = useBoxesList();
    const [showDialogButton, dialog, isAddLoading] = useAddBoxDialog(() => {
        triggerBoxesLoad();
        loadDashboards();
    });
    const [deleteBox, deleteDialog, isDeleteLoading] = useRemoveBox(() => {
        triggerBoxesLoad();
        loadDashboards();
    });

    return <Container maxWidth={"lg"} style={{width: '100%', paddingTop: 40}}>
        <Typography align="center" variant={"h3"} style={{marginBottom: 50}}>Liste des box connectées{showDialogButton}</Typography>
        <Grid container spacing={5}>
            {boxes.map(box => (
                <Grid item>
                    <Card key={box.boxId} style={{padding: 50, position: 'relative'}}>
                        <CardContent>
                            <Grid container justifyContent={"space-between"} alignItems={"flex-start"} direction={"column"}>
                                <TextField value={box.boxId} label={"Box"} variant={"standard"} disabled style={{marginBottom: 20}} inputProps={{style:{fontSize: 25}}}/>
                                <TextField value={box.username} label={"Nom du compte"} variant={"standard"} disabled style={{marginBottom: 20}} inputProps={{style:{fontSize: 25}}}/>
                                <TextField value={box.role === 1 ? "Utilisateur" : "Admin"} label={"Type de compte"} variant={"standard"} disabled inputProps={{style:{fontSize: 25}}}/>
                                <IconButton size={'large'} color={'primary'} style={{position: 'absolute', bottom: 10, right: 10}} onClick={() => deleteBox(box)}><DeleteIcon fontSize={'large'} /></IconButton>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
        {dialog}
        {deleteDialog}
    </Container>;
}

export default Screen;
