import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import fetchDashboard from '../Api/FetchDashboard'
import updateWidget from '../../Widget/Api/updateWidget'
import removeWidget from '../../Widget/Api/removeWidget'
import createWidget from '../../Widget/Api/createWidget'
import {getToken, logout} from '../../Login/Slice/me';
import Widget from "../../Widget/Component/Widget";
import GridWidget from "../../Widget/Component/GridWidget";

export function useDashboard(id, cellSize, editMode, onSuccess, onFail, onDashboardNotFound, cellFactor, gridMargin) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);
    const [widgets, setWidgets] = React.useState([]);
    const [name, setName] = React.useState("Dashboard");
    const [editable, setEditable] = React.useState(false);
    const [widgetList, setWidgetList] = React.useState([]);

    const trigger = () => {
        setIsLoading(true);
        fetchDashboard(id, token, response => {
            let widgets = response.data.widgets ?? [];
            widgets = widgets.sort((a, b) => {
                if (a.positionY >= b.positionY && a.positionY < (b.positionY + b.sizeY)) {
                    if (a.positionX < b.positionX) {
                        return 1;
                    } else if (a.positionX > b.positionX) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else if (b.positionY >= a.positionY && b.positionY < (a.positionY + a.sizeY)) {
                    if (b.positionX < a.positionX) {
                        return 1;
                    } else if (b.positionX > a.positionX) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
                if (a.positionY === b.positionY ) {
                    return (a.positionX - b.positionX );
                }
                return (a.positionY - b.positionY);
            });
            setWidgets(widgets);
            setName(response.data.name);
            setEditable(response.data.deletable); // dashboard is editable if it can be deleted (aka not a default dashboard)



            if (onSuccess) onSuccess();
        }, error => {
            if (error.response && error.response.status === 404) {
                if (onDashboardNotFound) onDashboardNotFound();
            } else if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    React.useEffect(() => {
        trigger();
        /*if (!editMode) {
            let interval = setInterval(() => {
                trigger();
            }, 5000);

            return () => {
                clearInterval(interval);
            }
        }*/
    }, [id]);

    React.useEffect(() => {
        let result = [];
        if (widgets) {

            for (const widget of widgets) {
                result.push({
                    widget: widget,
                    component: <GridWidget key={widget.id} widget={widget} />
                });
            }
        }
        setWidgetList(result);
    }, [widgets]);

    const addWidget = (widget, x, y, sizeX, sizeY) => {
        setWidgets(widgets => [
            ...(widgets ? widgets : []),
            {
                template: widget.template,
                data: widget.data,
                positionX: x,
                positionY: y,
                sizeX: sizeX,
                sizeY: sizeY
            }
        ]);
        createWidget(widget.id, x, y, sizeX, sizeY, id, token, trigger);
    }

    const deleteWidget = (widgetId) => {
        let index = widgets.findIndex((item) => item.id === widgetId);

        if (index !== -1) {
            removeWidget(id, widgetId, token, trigger);
            setWidgets(widgets => [
                ...widgets.slice(0, index),
                ...widgets.slice(index + 1)
            ])
        }
    }

    const updatePosition = (widgetId, newX, newY, newW, newH) => {
        let index = widgets.findIndex((item) => item.id === widgetId);

        if (index !== -1) {
            updateWidget(newX, newY, newW, newH, id, widgetId, token, trigger);
            setWidgets(widgets => [
                ...widgets.slice(0, index),
                Object.assign({}, widgets[index], {
                    positionX: newX,
                    positionY: newY,
                    sizeX: newW,
                    sizeY: newH
                }),
                ...widgets.slice(index + 1)
            ])
        }
    }

    return [widgetList, name, editable, trigger, updatePosition, addWidget, deleteWidget, isLoading];
}
