import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import register from '../Api/Register'

import RegisterForm from '../Component/RegisterForm';

import {useSnackbar} from "notistack";

export function useRegisterForm(styles, onSuccessful, onFailed) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);

    const onClick = (username, password) => {
        setIsLoading(true);
        register(username, password, (response) => {
            if (response.data) {
                enqueueSnackbar('Veuillez cliquer sur le lien que vous avez reçu par mail pour terminer la création de votre compte.', {variant: 'warning'});
                navigate("/");
                if (onSuccessful) onSuccessful();
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                enqueueSnackbar('Nom d\'utilisateur ou mot de passe incorrect', {variant: 'error'})
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFailed) onFailed();
        }, () => {
            setIsLoading(false);
        })

    }

    const component = <RegisterForm
        styles={styles}
        isLoading={isLoading}
        onValidate={onClick}
    />;

    return [component, isLoading];
}