import React from "react";

import {useDispatch, useSelector} from "react-redux";

import fetchDbg from '../Api/fetchWidgets'

import {getToken, logout} from '../../Login/Slice/me';
import {useSnackbar} from "notistack";
import Widget from "../Component/Widget";
import RightDrawer from "../Component/RightDrawer";
import RightDrawerWidget from "../Component/RightDrawerWidget";

export function useAvailableWidgetList(cellSize, editMode, defaultWidgetSize, isDraggingElement, setDeleting, onSuccess, onFail) {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);
    const [widgets, setWidgets] = React.useState([]);
    const [widgetList, setWidgetList] = React.useState([]);

    const loadWidgetList = () => {
        setIsLoading(true);
        fetchDbg(token, response => {
            setWidgets(response.data);
            if (onSuccess) onSuccess();
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    React.useEffect(() => {
        loadWidgetList();
        // let interval = setInterval(() => {
        //     loadWidgetList();
        // }, 5000);

        return () => {
            // clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        let result = [];
        for (const widget of widgets) {
            result.push(<RightDrawerWidget widget={widget} editMode={editMode} cellSize={cellSize} defaultWidgetSize={defaultWidgetSize}/>)
        }
        setWidgetList(result);
    }, [widgets]);


    const drawer = <RightDrawer
        setDeleting={setDeleting}
        isDraggingElement={isDraggingElement}
        defaultWidgetSize={defaultWidgetSize}
        editMode={editMode}
        isLoading={isLoading}
        widgets={widgetList}
    />

    return [drawer, isLoading];
}