import axios from "axios";
import env from "react-dotenv";

const query = (token, onSuccess, onFailure, onFinally) => {
    axios.get(env.API_URL + '/widgets', {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;