import React from "react";

import Container from "@mui/material/Container";
import {useSelector} from "react-redux";
import {getToken} from "../feature/Login/Slice/me";
import {Outlet, useNavigate} from "react-router-dom";
import {useBurgerMenu} from "../feature/BurgerMenu/Hook/useBurgerMenu";

function Screen() {
    const navigate = useNavigate();
    const token = useSelector(getToken);
    const [menu, toggleDrawer] = useBurgerMenu();

    React.useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token]);

    return <Container maxWidth={"lg"}>
        {menu}
        <Outlet />
    </Container>;
}

export default Screen;