import axios from "axios";
import env from "react-dotenv";

const query = (name, accountName, token, onSuccess, onFailure, onFinally) => {
    axios.delete(env.API_URL + '/boxes/' + name + '/' + accountName,
        {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;