import axios from "axios";
import env from "react-dotenv";

const query = (name, accountName, accountPassword, token, onSuccess, onFailure, onFinally) => {
    axios.post(env.API_URL + '/boxes',
        {
            name,
            accountName,
            accountPassword
        },
        {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;