import React, {useRef} from "react";
import { useDrop } from 'react-dnd'
import { useAsync } from "react-async"
import Container from "@mui/material/Container";
import {useNavigate, useParams} from "react-router-dom";
import {useDashboard} from "../feature/Dashboard/Hook/useDashboard";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {useAvailableWidgetList} from "../feature/Widget/Hook/useAvailableWidgetList";
import { Responsive as GridLayout } from "react-grid-layout";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

const DEFAULT_WIDGET_SIZE = 4;

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });
    React.useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

function getCell(item, monitor, cellSize) {
    const delta = monitor.getDifferenceFromInitialOffset()
    let left = item.left + Math.floor(delta.x / cellSize);
    let top = item.top + Math.floor(delta.y / cellSize);

    return [left, top];
}

const breakpoints = {
    'sm': 24,
    'xs': 8
}

function Screen() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const onDashboardNotFound = () => {
        navigate('/');
    }

    const {id} = useParams();
    const windowSize = useWindowSize();
    const [deleting, setDeleting] = React.useState(false);
    const [isDraggingElement, setIsDraggingElement] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [cellSize, setCellSize] = React.useState(42);
    const [margin, setMargin] = React.useState(30);
    const [cellFactor, setCellFactor] = React.useState(1);
    const [widgetList, dashboardName, dashboardEditable, loadDashboard, updatePosition, addWidget, deleteWidget, isLoading] = useDashboard(id, cellSize, editMode, null, null, onDashboardNotFound, cellFactor, margin);
    const containerRef = useRef();
    const [availableWidgetsDrawer] = useAvailableWidgetList(cellSize, editMode, DEFAULT_WIDGET_SIZE, isDraggingElement, setDeleting);
    const [oldLayout, setOldLayout] = React.useState([]);

    const getCellSize = () => {
        if (containerRef.current) {
            let nbCells, newMargin;
            if (matches) {
                nbCells = breakpoints['sm'];
                setCellFactor(1);
                newMargin = 30;
            } else {
                nbCells = breakpoints['xs']
                setCellFactor(0.5);
                newMargin = 5;
            }
            let newCellSize = (containerRef.current.clientWidth / nbCells) - newMargin;
            setCellSize(newCellSize);
            setMargin(newMargin);
        }
    };

    React.useEffect(() => {
        getCellSize();
    }, [windowSize]);

    React.useEffect(() => {
        setEditMode(false);
    }, [id])

    const onLayoutChange = (layout) => {
        if (editMode && oldLayout.length !== 0) {
            for (const item of layout) {
                let index = oldLayout.findIndex(oldItem => oldItem.i === item.i);
                if (index !== -1 && (oldLayout[index].x !== item.x || oldLayout[index].y !== item.y || oldLayout[index].w !== item.w || oldLayout[index].h !== item.h)) {
                    updatePosition(parseInt(item.i), item.x, item.y, item.w, item.h);
                }
            }
        }
        setOldLayout(layout);
    }

    const onDrop = (layout, layoutItem, _event) => {
        const data = JSON.parse(_event.dataTransfer.getData('dragData'));
        addWidget(data, layoutItem.x, layoutItem.y, layoutItem.w, layoutItem.h);
    };

    const onDragStart = (layout, oldItem, newItem, e) => {
        if (oldItem.i !== '-1') {
            setIsDraggingElement(true);
        }
    }

    const onDragStop = (layout, oldItem, newItem, e) => {
        if (oldItem.i !== '-1') {
            setIsDraggingElement(false);
            if (deleting) {
                setDeleting(false);
                deleteWidget(parseInt(oldItem.i));
            }
        }
    }

    let widgetParams = [];
    const getWidgetParams = (index) => {
        let widget = widgetList[index];
        let result;
        if (matches) {
            result = {
                'x': Math.floor(widget.widget.positionX * cellFactor),
                'y': Math.floor(widget.widget.positionY * cellFactor),
                'w': widget.widget.sizeX,
                'h': widget.widget.sizeY
            }
        } else {
            let y = 0;
            let x = 0;
            if (widgetParams.length > 0) {
                let occupiedWidth = 0;
                let maxY = 0;
                for (let i = index - 1; i >= 0; i--) {
                    let y = widgetParams[i].y + widgetParams[i].h;
                    occupiedWidth += widgetParams[i].w;
                    maxY = y > maxY ? y : maxY;
                    if (widgetParams[i].x === 0) {
                        break;
                    }
                }
                let previousWidgetParams = widgetParams[index - 1];
                // If the previous line + this widget's width are bigger in blocs than the line, we put it on the next line
                if (occupiedWidth + widget.widget.sizeX > breakpoints['xs']) {
                    y = maxY;
                    // If we put it on the next, line, we make sure the previous widget takes all remaining horizontal space
                    if (occupiedWidth < breakpoints['xs']) {
                        previousWidgetParams.w = previousWidgetParams.w + (breakpoints['xs'] - occupiedWidth);
                    }
                } else {
                    y = previousWidgetParams.y;
                    x = occupiedWidth;
                }
            }
            result = {
                'x': x,
                'y': y,
                'w': Math.min(widget.widget.sizeX, breakpoints['xs']),
                'h': widget.widget.sizeY
            }
        }
        widgetParams.push(result);
        return result;
    }

    return <Container maxWidth={"lg"} style={{width: '100%', paddingTop: 20, paddingLeft: 0, paddingRight: 0, textAlign: "center"}} ref={containerRef}>
        {/* <Typography align="center" variant={"h3"} style={{marginBottom: 0}}>{dashboardName} { dashboardEditable && <IconButton sx={{display: {xs: 'none', md: 'inline'}}} color={'primary'} size={'large'} onClick={() => setEditMode(!editMode)}>{editMode ? <LockOpenIcon fontSize={'large'} /> : <LockIcon fontSize={'large'} />}</IconButton>}</Typography> */}
        <img align="center" style={{margin: "auto", height:"100px"}} src={"/"+dashboardName+".png"}></img>
        <GridLayout
            preventCollision={false}
            compactType={'vertical'}
            style={{minHeight: "50vh"}}
            breakpoints={{ sm: 600, xs: 0 }}
            cols={{ sm: breakpoints['sm'], xs: breakpoints['xs'] }}
            className={"layout"}
            margin={[margin, margin]}
            rowHeight={cellSize}
            onLayoutChange={onLayoutChange}
            width={(cellSize + margin) * 24}
            isDraggable={editMode}
            isResizable={editMode}
            measureBeforeMount={true}
            isDroppable={editMode}
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDragStop={onDragStop}
            droppingItem={{i: "-1", w: DEFAULT_WIDGET_SIZE, h:DEFAULT_WIDGET_SIZE}}>
            {widgetList.map((widget, index) => {
                let widgetParams = getWidgetParams(index);
                return <div key={widget.widget.id} data-grid={widgetParams}>
                    {widget.component}
                    {/*<span style={{position: 'absolute', top: 0, left: 0}}>{index}</span>*/}
                </div>
            })}
        </GridLayout>
        {availableWidgetsDrawer}
    </Container>;
}

export default Screen;
