export default {
    fieldWrapper: {
        width: '100%',
        marginBottom: "10px",
        '&:nth-last-child(3)': {
            marginBottom: "30px",
        }
    },
    title: {
        marginBottom: "20px",
        color: (theme) => theme.palette.primary.main,
        fontWeight: "bold",
        // textTransform: "uppercase",
        fontSize: '1.8rem',
        textAlign: "center"
    },
    text: {
        color: "#8e8e8e",
        textAlign: "center",
        marginBottom: "10px"
    },
    field: {
        width: '100%'
    },
    submitButton: {
        marginBottom: "10px"
    }
};
