import React, {useRef} from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Widget from "./Widget";

const Component = ({ widget }) => {
    const padding = 20;
    const ref = useRef();
    const [paperSize, setPaperSize] = React.useState({w: 0, h: 0});
    const styles = {
        wrapper: {
            fontSize: {xs: '12px', md: '16px'},

            '& .MuiTypography-root': {
                fontSize: '1em'
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1.5em'
            },
            '& .rv-discrete-color-legend-item__title': {
                fontSize: {xs: '10px', md: '12px'}
            }
        },
    };

    React.useEffect(() => {
        if (ref.current) {
            setPaperSize({w: ref.current.clientWidth - (padding * 2), h: ref.current.clientHeight - (padding * 2)});
        }
    }, [ref]);


    if (ref.current) {
        let newSize = {w: ref.current.clientWidth - (padding * 2), h: ref.current.clientHeight - (padding * 2)};
        if (paperSize.w !== newSize.w || paperSize.h !== newSize.h) {
            setPaperSize(newSize);
        }
    }

    return (
        <Grid key={widget.id} item style={{width: '100%', height: '100%'}} sx={styles.wrapper}>
            <Paper elevation={5} style={{width: '100%', height: '100%', padding: padding, boxSizing: "border-box"}} ref={ref}>
                <Widget widget={widget} width={paperSize.w} height={paperSize.h} />
            </Paper>
        </Grid>
    );
};

export default Component;
