import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import createDashboard from '../Api/CreateDashboard'
import {getToken, logout} from '../../Login/Slice/me';
import CreateDashboardDialog from "../Component/CreateDashboardDialog";

export function useDashboardCreate(onSuccess, onFail) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);
    const [dialogVisible, setDialogVisible] = React.useState(false);

    const trigger = (name, defaultDash, icon) => {
        setIsLoading(true);
        createDashboard(name, defaultDash, icon, token, response => {
            if (onSuccess) onSuccess(response.data);
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    const dialog = <CreateDashboardDialog
        onValidate={trigger}
        open={dialogVisible}
        setOpen={setDialogVisible}
    />

    return [setDialogVisible, dialog, isLoading];
}
