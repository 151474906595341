import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import addBox from '../Api/AddBox'
import {getToken, logout} from '../../Login/Slice/me';
import AddBoxDialog from "../Component/AddBoxDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";

export function useAddBoxDialog(onSuccess, onFail) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [addBoxDialogVisible, setAddBoxDialogVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const trigger = (name, accountName, password) => {
        setIsLoading(true);
        addBox(name, accountName, password, token, response => {
            enqueueSnackbar('Box bien ajoutée', {variant: 'success'})
            setAddBoxDialogVisible(false);
            if (onSuccess) onSuccess();
        }, error => {
            let errorMessage = 'Une erreur inconnue a eu lieu';
            let variant = 'error';
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case 401:
                        errorMessage = 'Vous avez été déconnecté. Veuillez vous reconnecter.';
                        variant = 'warning';
                        dispatch(logout());
                        break;
                    case 400:
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }
                        setAddBoxDialogVisible(false);
                        variant = 'warning';
                        break;
                    case 404:
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }
                        break;
                }
            }

            enqueueSnackbar(errorMessage, {variant: variant})

            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    const showDialogButton = <IconButton color={"primary"} style={{marginLeft: 15}} onClick={() => setAddBoxDialogVisible(true)}><AddCircleIcon style={{fontSize: 40}} /></IconButton>;

    const dialog =
        <AddBoxDialog
            onValidate={trigger}
            open={addBoxDialogVisible}
            setOpen={setAddBoxDialogVisible}>

        </AddBoxDialog>;

    return [showDialogButton, dialog, isLoading];
}