import React from "react";

import {useLoginForm} from "../feature/Login/Hook/useLogin"
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import LoginStyles from "../style/LoginStyles"
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getMe, getToken} from "../feature/Login/Slice/me";
import {useValidateAccount} from "../feature/Login/Hook/useValidateAccount";



function Screen() {
    const token = useSelector(getToken);
    const me = useSelector(getMe);
    const navigate = useNavigate();
    const [loginForm] = useLoginForm(LoginStyles);
    const [isLoading] = useValidateAccount();

    React.useEffect(() => {
        if (token) {
            console.log(me);
            if (me && me.defaultDashboard) {
                navigate("/app/dashboard/" + me.defaultDashboard);
            } else {
                navigate("/app/settings");
            }
        }
    }, [token]);

    return <Container maxWidth={"xs"} style={{paddingTop: 50}}>
        <Paper style={{paddingTop: 50, paddingBottom: 50, paddingLeft: 30, paddingRight: 30}}>
            {loginForm}
        </Paper>
    </Container>;
}

export default Screen;
