import LoginStyles from "./LoginStyles";

export default Object.assign({}, LoginStyles, {
    fieldWrapper: {
        width: '100%',
        marginBottom: "10px",
        '&:nth-last-child(2)': {
            marginBottom: "30px",
        }
    },
});
