import React from "react";

import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Component({open, setOpen, onValidate}) {
    const [name, setName] = React.useState("");
    const [accountName, setAccountName] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleValidate = () => {
        onValidate(name, accountName, password);
    }

    return <Dialog
        open={open}>
        <DialogTitle>Ajouter une box</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Veuillez entrez les informations de votre box pour vous y connecter.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nom de la box"
                type="text"
                fullWidth
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <TextField
                margin="dense"
                id="account-name"
                label="Nom du compte"
                type="text"
                fullWidth
                variant="outlined"
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
            />
            <TextField
                margin="dense"
                id="account-password"
                label="Mot de passe"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={handleValidate}>Connecter la box</Button>
        </DialogActions>
    </Dialog>;
}

export default Component;
