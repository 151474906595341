import React from "react";

import CircularProgress from '@mui/material/CircularProgress';

import L from "leaflet";
import * as babel from "babel-standalone";
import {  PieChart, Pie, Sector, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Surface, Symbols, LabelList} from "recharts";
import {  MapContainer, Marker, CircleMarker, Popup, TileLayer } from "react-leaflet";
import { CSVLink, CSVDownload } from "react-csv";
//import {XYPlot, VerticalGridLines, HorizontalGridLines, LineSeries, VerticalBarSeries, MarkSeries, DiscreteColorLegend, RadialChart} from "react-vis";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BarChartIcon from '@mui/icons-material/BarChart';
import PlaceIcon from '@mui/icons-material/Place';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import FilterListIcon from '@mui/icons-material/FilterList';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TableChartIcon from '@mui/icons-material/TableChart';
import DownloadIcon from '@mui/icons-material/Download';
import EuroIcon from '@mui/icons-material/Euro';
import MarkerClusterGroup from "react-leaflet-markercluster";
import CustomBarChart from "./CustomBarChart"
import { Async } from "react-async"
import {getToken} from '../../Login/Slice/me';
import {useSelector} from "react-redux";
import env from "react-dotenv";

const INCLUDES = {
    "React": React,
    "L": L,
    "PieChart": PieChart,
    "LabelList":LabelList,
    "Pie": Pie,
    "Sector": Sector,
    "LineChart": LineChart,
    "Line": Line,
    "BarChart": BarChart,
    "Bar": Bar,
    "Cell": Cell,
    "XAxis": XAxis,
    "YAxis": YAxis,
    "CartesianGrid": CartesianGrid,
    "Tooltip": Tooltip,
    "Legend": Legend,
    "ResponsiveContainer": ResponsiveContainer,
    "Grid": Grid,
    "Typography": Typography,
    "BarChartIcon": BarChartIcon,
    "SsidChartIcon": SsidChartIcon,
    "DonutLargeIcon": DonutLargeIcon,
    "TableChartIcon": TableChartIcon,
    "FilterListIcon": FilterListIcon,
    "Paper" : Paper,
    "CSVLink" : CSVLink,
    "CSVDownload" : CSVDownload,
    "DownloadIcon" : DownloadIcon,
    "EuroIcon" : EuroIcon,
    "Stack" : Stack,
    "MapContainer": MapContainer,
    "TileLayer":TileLayer,
    "Marker":Marker,
    "CircleMarker":CircleMarker,
    "Popup": Popup,
    "PlaceIcon": PlaceIcon,
    "Surface": Surface,
    "Symbols":Symbols,
    "MarkerClusterGroup":MarkerClusterGroup,
    "TrendingUpIcon":TrendingUpIcon,
    "TrendingDownIcon":TrendingDownIcon,
    "CustomBarChart":CustomBarChart

};

const loadWidget = async ({ widgetId, token }, { signal }) => {
    const res = await fetch(env.API_URL  + '/widget/' + widgetId, {
        headers: {
            "Authorization": "Bearer " + token
        }
    }, { signal })
    if (!res.ok) throw new Error(res.statusText)
    var ret = await res.json();

    return ret
}

let previousState = []
const Component = ({ widget, width, height, isLoading, cellSize }) => {
    const token = useSelector(getToken);

    // if (!widget.autoUpdate) {
    //     if (previousState[widget.id.toString()])
    //     {
    //         return previousState[widget.id.toString()];
    //     }
    // }

    return <Async promiseFn={loadWidget} widgetId={widget.id} token={token}>
    {({ data, error, isPending }) => {

        if (isPending) {
            return <Grid container justifyContent={"center"} alignItems={"center"} style={{height: '100%'}} >
                <CircularProgress/>
            </Grid>;
        }
        if (error) return `Something went wrong: ${error.message}`
        if (data) {
            let template = data.template;
            let mydata = JSON.parse(data.data);
           
            if (mydata.color == undefined) {
                mydata.color = "red"
            }

            const styles = {
                header: {
                    height: 50,
                },
                bottomLegend: {
                    height: 20
                },
                rightLegend: {
                    width: 100,
                },
                responsiveText: {
                    fontSize: "1em"
                },
                scrollTable:{
                overflowY: 'hidden',
                overflowX: 'hidden',
                scrollbarWidth: 'none',
                width: width,
                height: height - 60
                },
                boldText: {
                fontWeight: 'bold',
                fontSize: "1em"
                },
                littleText : {
                fontSize: "0.7em"
                },
                map: {
                    position: "relative",
                    height: height,
                    width: width,
                    top:0,
                    bottom:0,
                    overflow: "hidden"
                },
                titleContainer:{
                    backgroundColor: mydata.color,
                    height: height + 40,
                    width: width + 40,
                    margin: "-20px"
                },
                titleWidget: {
                    color: "white",
                    display: "block",
                    textAlign: "center",
                    paddingTop: "-20px",
                    paddingBottom: "-20px",
                    fontSize: height < 20 ? 25+"px" : height + "px",
                    width: width
                },
                centeredTypo: {
                    flexGrow: 1,
                    textAlign: "center",
                    fontWeight: "bold"
                }
            };
            let CPT = () => null;
            if (template) {
                let babelCode = babel.transform(template, {presets: ['react', 'es2015']}).code;
                babelCode = babelCode.replace('"use strict";', "").trim();
                eval(babelCode);
                console.log(babelCode)

                const func = new Function([...Object.keys(INCLUDES), "data", "styles"], `return ${babelCode}`);
                CPT = func(...Object.values(INCLUDES), mydata, styles)
            }

            let ret = <CPT width={width} height={height} />;

            if (!widget.autoUpdate) {
                previousState[widget.id.toString()] = ret;
            }
            return ret;
        }
        return null;


    }}

    </Async>
};
let MemoizedComponent = React.memo(Component);

export default MemoizedComponent;
