import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import fetchBoxes from '../Api/FetchBoxes'
import {getToken, logout} from '../../Login/Slice/me';

export function useBoxesList(onSuccess, onFail) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);
    const [boxes, setBoxes] = React.useState([]);

    const trigger = () => {
        setIsLoading(true);
        fetchBoxes(token, response => {
            setBoxes(response.data)
            if (onSuccess) onSuccess();
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    React.useEffect(() => {
        trigger();
    }, [])

    return [boxes, trigger, isLoading];
}