import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import removeBox from '../Api/RemoveBox'
import {getToken, logout} from '../../Login/Slice/me';
import RemoveBoxDialog from "../Component/RemoveBoxDialog";

export function useRemoveBox(onSuccess, onFail) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [boxToDelete, setBoxToDelete] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const trigger = (name, accountName) => {
        setIsLoading(true);
        removeBox(name, accountName, token, response => {
            enqueueSnackbar('Box supprimée', {variant: 'success'})
            setBoxToDelete(null);
            if (onSuccess) onSuccess();
        }, error => {
            let errorMessage = 'Une erreur inconnue a eu lieu';
            let variant = 'error';
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case 401:
                        errorMessage = 'Vous avez été déconnecté. Veuillez vous reconnecter.';
                        variant = 'warning';
                        dispatch(logout());
                        break;
                    case 400:
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }
                        setBoxToDelete(null);
                        variant = 'warning';
                        break;
                    case 404:
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }
                        break;
                }
            }

            enqueueSnackbar(errorMessage, {variant: variant})

            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    const dialog =
        <RemoveBoxDialog
            box={boxToDelete}
            onValidate={trigger}
            open={boxToDelete !== null}
            closeDialog={() => setBoxToDelete(null)}>

        </RemoveBoxDialog>;

    return [setBoxToDelete, dialog, isLoading];
}