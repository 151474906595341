import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import login from '../Api/Login'
import fetchMe from '../Api/FetchMe'

import LoginForm from '../Component/LoginForm';

import {setMe, setToken} from '../Slice/me';
import {useSnackbar} from "notistack";

export function useLoginForm(styles, onLoginSuccessful, onLoginFailed) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);

    const onClick = (username, password) => {
        setIsLoading(true);
        login(username, password, (response) => {
            if (response.data) {
                fetchMe(response.data.token, response2 => {
                    dispatch(setMe(response2.data));
                    dispatch(setToken(response.data.token));
                    if (onLoginSuccessful) onLoginSuccessful();
                }, error2 => {
                    enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
                    if (onLoginFailed) onLoginFailed();
                }, () => {
                    setIsLoading(false);
                })
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                if (error.response.data.message === 'account_not_validated') {
                    enqueueSnackbar('Votre compte n\'est pas activé. Veuillez suivre la procédure que vous avez reçu par e-mail.', {variant: 'error'})
                } else {
                    enqueueSnackbar('Nom d\'utilisateur ou mot de passe incorrect', {variant: 'error'})
                }
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onLoginFailed) onLoginFailed();
        }, () => {
            setIsLoading(false);
        })

    }

    const component = <LoginForm
        styles={styles}
        isLoading={isLoading}
        onValidate={onClick}
        onResetPassword />;

    return [component, isLoading];
}
