import * as React from 'react';
import Box from '@mui/material/Box';
import { pink } from '@mui/material/colors';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export default function HomeIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 22 32">
            <path d="M22 20.0633V23.6972L11.0111 32L0 23.6972V20.0853L10.6123 28.1019C10.7231 28.1899 10.8781 28.234 11.0111 28.234C11.0775 28.234 11.144 28.212 11.2105 28.19C11.2769 28.1679 11.3434 28.1239 11.4099 28.0798L22 20.0633Z" fill="#F44336"/>
            <path d="M22 14.8217V18.4116L11.0111 26.7364L0 18.4336V14.8438L10.6123 22.8603C10.7231 22.9484 10.8781 22.9924 11.0111 22.9924C11.0775 22.9924 11.144 22.9704 11.2105 22.9484C11.2769 22.9264 11.3434 22.8823 11.4099 22.8383L22 14.8217Z" fill="#F44336"/>
            <path d="M22 9.6022V13.214L11.0111 21.5389L0 13.214V9.6022L10.6123 17.6187C10.856 17.7949 11.1883 17.7949 11.4099 17.6187L22 9.6022Z" fill="#F44336"/>
            <path d="M13.138 5.37371L13.5589 7.79628L11.3656 6.65106C11.144 6.54095 10.8782 6.54095 10.6566 6.65106L8.44112 7.79628L8.86206 5.37371C8.90637 5.13145 8.81775 4.88919 8.64051 4.71301L6.84595 2.97316L9.32732 2.62078C9.57102 2.57674 9.79258 2.42257 9.90335 2.20234L11.0111 0L12.1189 2.20234C12.2296 2.42257 12.4512 2.57674 12.6949 2.62078L15.1541 2.97316L13.3595 4.71301C13.1823 4.88919 13.0937 5.13145 13.138 5.37371Z" fill="#F44336"/>
        </SvgIcon>
    );
}
// <svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg">
// </svg>
