import React from "react";
import { useTheme } from '@mui/material/styles';
import {useNavigate, useMatch, useResolvedPath} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";

function Screen({link, label, deletable, icon, customIcon, fct, open, action}) {
    const navigate = useNavigate();
    const resolved = useResolvedPath(link);
    const match = useMatch({path: resolved.pathname, end: true});
    const theme = useTheme();

    let logo = icon;
    if (customIcon && customIcon !== -1) {
        let decodedBase64 = window.atob(customIcon);
        decodedBase64 = decodedBase64.replace('{color}', match ? theme.palette.primary.main : theme.palette.primary.alternate);
        
         logo = <CardMedia>
             <img style={{width: 24, height: 24}} src={"data:image/svg+xml;base64," + window.btoa(decodedBase64)} />
         </CardMedia>;
    }

    return (
        <ListItemButton
            onClick={(e) => {e.stopPropagation(); fct ? fct() : navigate(link);}}
            key={label}
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: match ? theme.palette.primary.main : theme.palette.primary.alternate
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: match ? theme.palette.primary.main : theme.palette.primary.alternate
                }}
            >
                {logo}
            </ListItemIcon>
            <ListItemText primary={label} sx={{opacity: open ? 1 : 0, whiteSpace: 'normal'}}/>
            {action && open &&
                <IconButton size={'large'} color={'secondary'} onClick={(e) => action(e)}><SettingsIcon
                    fontSize={'small'}/></IconButton>}


        </ListItemButton>
    );
}

export default Screen;
