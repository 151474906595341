import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import fetchDashboards from '../Api/FetchDashboards'
import removeDashboard from '../Api/RemoveDashboard'
import {getToken, logout} from '../../Login/Slice/me';
import RemoveDashboardDialog from "../Component/RemoveDashboardDialog";
import {getDashboards, setDashboards} from "../Slice/dashboards";
import CreateDashboardDialog from "../Component/CreateDashboardDialog";
import editDashboardApi from "../Api/EditDashboard";

export function useDashboardList(onSuccess, onFail) {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = React.useState(false);
    // const [dashboards, setDashboards] = React.useState([]);
    const dashboards = useSelector(getDashboards);
    const [isRemoveModalOpen, setRemoveModalOpen] = React.useState(false);
    const [isEditModalOpen, setEditModalOpen] = React.useState(false);
    const [currentEditDashboard, setCurrentEditDashboard] = React.useState(null);

    const trigger = () => {
        setIsLoading(true);
        fetchDashboards(token, response => {
            dispatch(setDashboards(response.data))
            if (onSuccess) onSuccess();
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
        });
    }

    const deleteDashboard = (id) => {
        setIsLoading(true);
        removeDashboard(id, token, response => {
            trigger();
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
        }, () => {
            setIsLoading(false);
            setCurrentEditDashboard(null);
            setEditModalOpen(false);
        });
    };

    const editDashboard = (e, dashboard) => {
        e.stopPropagation();
        setEditModalOpen(true);
        setCurrentEditDashboard(dashboard);
    }
    const triggerEditDashboard = (name, defaultDash, icon) => {
        setIsLoading(true);
        editDashboardApi(currentEditDashboard.id, name, defaultDash, icon, token, response => {
            trigger();
            if (onSuccess) onSuccess(response.data);
        }, error => {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Vous avez été déconnecté. Veuillez vous reconnecter.', {variant: 'warning'});
                dispatch(logout());
            } else {
                enqueueSnackbar('Une erreur inconnue a eu lieu', {variant: 'error'})
            }
            if (onFail) onFail();
        }, () => {
            setIsLoading(false);
            setCurrentEditDashboard(null);
            setEditModalOpen(false);
        });
    }

    const askDeleteDashboard = (dashboard) => {
        setRemoveModalOpen(true);
    }

    const editModal = <CreateDashboardDialog
        onValidate={triggerEditDashboard}
        open={isEditModalOpen}
        setOpen={setEditModalOpen}
        dashboard={currentEditDashboard}
        onClickDelete={askDeleteDashboard}
    />;
    const removeModal = <RemoveDashboardDialog
        open={isRemoveModalOpen}
        setOpen={setRemoveModalOpen}
        onValidate={id => deleteDashboard(id)}
        dashboard={currentEditDashboard} />

    React.useEffect(() => {
        trigger();
    }, [])

    return [dashboards, trigger, editDashboard, editModal, removeModal, isLoading];
}
