import axios from "axios";
import env from "react-dotenv";

const query = (id, token, onSuccess, onFailure, onFinally) => {
    axios.delete(
        env.API_URL + '/dashboard/' + id,
        {
            headers: {
                "Authorization": "Bearer " + token
            }
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;