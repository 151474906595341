import axios from "axios";
import env from "react-dotenv";

const query = (username, password, onSuccess, onFailure, onFinally) => {
    axios.post(env.API_URL + '/register', {
        'username': username,
        'password': password
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;