import React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getMe, getToken} from "../feature/Login/Slice/me";
import {useRegisterForm} from "../feature/Login/Hook/useRegister";
import RegisterStyles from "../style/RegisterStyles";



function Screen() {
    const token = useSelector(getToken);
    const me = useSelector(getMe);
    const navigate = useNavigate();
    const [registerForm] = useRegisterForm(RegisterStyles);

    React.useEffect(() => {
        if (token) {
            navigate("/app/settings");
        }
    }, [token]);

    return <Container maxWidth={"xs"} style={{paddingTop: 50}}>
        <Paper style={{paddingTop: 50, paddingBottom: 50, paddingLeft: 30, paddingRight: 30}}>
            {registerForm}
        </Paper>
    </Container>;
}

export default Screen;
