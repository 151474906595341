import React from "react";

import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Component({dashboard, open, setOpen, onValidate}) {

    const handleClose = () => {
        setOpen(false);
    };

    const handleValidate = () => {
        onValidate(dashboard.id);
        setOpen(false);
    }

    return <Dialog
        open={open}>
        <DialogTitle>Êtes-vous sûr de vouloir supprimer le dashboard {dashboard ? dashboard.name : ''} ?</DialogTitle>
        <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={handleValidate}>Valider</Button>
        </DialogActions>
    </Dialog>;
}

export default Component;
