import React from "react";

import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Component({box, open, closeDialog, onValidate}) {
    const handleClose = () => {
        closeDialog();
    };

    const handleValidate = () => {
        onValidate(box.boxId, box.username);
    }

    return <Dialog
        open={open}>
        <DialogTitle>Supprimer une box</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Êtes-vous sûr de vouloir supprimer la box <b>{box ? box.boxId : ''}</b> ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={handleValidate}>Supprimer la box</Button>
        </DialogActions>
    </Dialog>;
}

export default Component;
