import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";


const Component = props => {
    const {isLoading, children, ...otherProps} = props;
    return <Button {...otherProps} disabled={isLoading}>
        {children}
        {isLoading && <CircularProgress size={24} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: -12,
            marginTop: -12
        }} />}
    </Button>;
};

export default Component;