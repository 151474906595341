import React from "react";
import { styled, useTheme } from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";

import MuiDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ChevronIcon from '../../../svg/logoLeftDrawer.js';

import { logout } from '../../Login/Slice/me';
import DrawerButton from "./DrawerButton";
import {useDashboardList} from "../../Dashboard/Hook/useDashboardList";
import {useDashboardCreate} from "../../Dashboard/Hook/useDashboardCreate";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import {getDisplayIcon, setRightDrawerOpen, toggleRightDrawer} from "../Slice/rightAction";
import CardMedia from "@mui/material/CardMedia";


const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Screen({open, toggleDrawer}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const displayRightIcon = useSelector(getDisplayIcon);
    const [dashboards, loadDashboards, editDashboard, editModal, deleteDashboardDialog, dashboardsLoading] = useDashboardList();

    const [setDialogVisible, dialog, isLoading] = useDashboardCreate((data) => {
        loadDashboards();
        setDialogVisible(false);
        navigate('/app/dashboard/' + data.id);
    });


    const anchor = 'left';

    const topMenuItems = [
    ];

    const bottomMenuItems = [
        {
            'label': 'Mes box',
            'link': '/app/settings',
            'icon': <SettingsIcon />
        },
        // {
        //     'label': 'Mon compte',
        //     'link': '/app/account',
        //     'icon': <AccountIcon />
        // },
        {
            'label': 'Se déconnecter',
            'link': '/',
            'fct': () => {
                dispatch(logout());
            },
            'icon': <LogoutIcon />
        }
    ];

    
    const addDashboardButton = {
        'label': 'Nouveau dashboard',
        'link': '/',
        'fct': () => {
            setDialogVisible(true);
        },
        'icon': <AddCircleIcon />
    };


    const drawerContent = <div>
        <DrawerHeader style={{justifyContent: 'flex-start'}}>
            <IconButton style={{transition: open ? 'margin-left 0.25s 0.25s' : 'margin-left 0.25s', marginLeft: open ? 10 : -5, width: 60}} onClick={toggleDrawer(!open)}>
                <ChevronIcon style={{transition: 'transform 0.25s', transform: open ? 'rotate(-90deg)' : 'initial', fontSize: 40}} />
            </IconButton>
            <Typography color={'primary'} onClick={toggleDrawer(!open)} style={{cursor: 'pointer', fontSize: 20, transformOrigin: 'left', transition: open ? 'width 0.25s, transform 0.25s 0.25s' : '', width: !open ? '0px': 'initial', transform: !open ? 'scaleX(0%)' : 'initial'}}>DataOfficer.ai</Typography>
        </DrawerHeader>
        <Divider />
        <List>
            {topMenuItems.map((params, index) => (
                <DrawerButton {...params} open={open} />
            ))}
            {dashboards && dashboards.map((dashboard, index) => {
                return <DrawerButton link={"/app/dashboard/" + dashboard.id} icon={<DashboardIcon />} customIcon={dashboard.logo} deletable={dashboard.deletable} label={dashboard.name} open={open} action={(e) => editDashboard(e, dashboard)}/>
            })}
            <DrawerButton {...addDashboardButton} open={open} />
        </List>
        <List style={{marginTop: 'auto'}}>
            <Divider />
            {bottomMenuItems.map((params, index) => (
                <DrawerButton {...params} open={open} />
            ))}
        </List>
        {dialog}
        {deleteDashboardDialog}
        {editModal}
    </div>;

    return <Box sx={{height: {xs: '45px', md: '0'}}}>

        <AppBar
            position="fixed"
            sx={{
                display: { md: 'none' },
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer(true)}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" color={"white"} sx={{flexGrow: 1}}>
                    DataOfficer.ai
                </Typography>
                {displayRightIcon && <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => dispatch(toggleRightDrawer())}
                    sx={{ ml: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>}
            </Toolbar>
        </AppBar>
            <Drawer
                anchor={anchor}
                variant={"permanent"}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{
                    display: { xs: 'none', md: 'block' },
                }}
            >
                {drawerContent}
            </Drawer>
            <Drawer
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
                anchor={anchor}
                variant={"temporary"}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}>
                    {drawerContent}
            </Drawer>
    </Box>;
}

export default Screen;
