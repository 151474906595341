import { createSlice } from '@reduxjs/toolkit'

export const rightDrawerOpen = createSlice({
    name: 'rightDrawerOpen',
    initialState: {
        display: false,
        open: false
    },
    reducers: {
        setRightDrawerOpen: (state, {type, payload}) => {
            state.open = payload;
        },
        toggleRightDrawer: (state, {type, payload}) => {
            state.open = !state.open;
        },
        setDisplayIcon: (state, {type, payload}) => {
            state.display = payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRightDrawerOpen, toggleRightDrawer, setDisplayIcon } = rightDrawerOpen.actions

export const { getRightDrawerOpen, getDisplayIcon } = {
    getRightDrawerOpen: (state) => state.rightDrawerOpen.open,
    getDisplayIcon: (state) => state.rightDrawerOpen.display,
};

export default rightDrawerOpen.reducer