import axios from "axios";
import env from "react-dotenv";

const query = (name, defaultDash, logo, token, onSuccess, onFailure, onFinally) => {
    let data = {
        name,
        defaultDashboard: defaultDash,
    }

    if (logo !== -1) {
        data['boxLogo'] = logo;
    }
    axios.post(
        env.API_URL + '/dashboards',
        data ,{
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(onSuccess).catch(onFailure).finally(onFinally);
}

export default query;
