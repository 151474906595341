import React from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import LoadingButton from "../../../component/LoadingButton";

const Component = ({styles, isLoading, onValidate, onResetPassword}) => {

    const [errors, setErrors] = React.useState([]);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!username) {
            newErrors.username = "Ce champ est obligatoire.";
        }

        if (!password) {
            newErrors.password = "Ce champ est obligatoire.";
        }

        if (password !== password2) {
            newErrors.password2 = "Les deux mots de passe ne correspondent pas.";
        }

        setErrors(Object.assign({}, errors, newErrors));
        if (Object.keys(newErrors).length === 0) {
            onValidate(username, password);
        }
    }

    const handleChangeUsername = (e) => {
        setErrors(Object.assign({}, errors, {username: null}));
        setUsername(e.target.value)
    }

    const handleChangePassword = (e) => {
        setErrors(Object.assign({}, errors, {password: null}));
        setPassword(e.target.value)
    }

    const handleChangePassword2 = (e) => {
        setErrors(Object.assign({}, errors, {password2: null}));
        setPassword2(e.target.value)
    }


    return <form onSubmit={onSubmit}>
        <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
            <Typography variant={"h4"} sx={styles.title}>Créer mon compte</Typography>
            <Grid item sx={styles.fieldWrapper}>
                <TextField
                    variant={"outlined"}
                    value={username}
                    onChange={handleChangeUsername}
                    label={"Adresse e-mail"}
                    sx={styles.field}
                    error={!!errors.username}
                    helperText={errors.username} />
            </Grid>
            <Grid item sx={styles.fieldWrapper}>
                <TextField
                    variant={"outlined"}
                    type={"password"}
                    value={password}
                    onChange={handleChangePassword}
                    label={"Mot de passe"}
                    sx={styles.field}
                    error={!!errors.password}
                    helperText={errors.password} />
            </Grid>
            <Grid item sx={styles.fieldWrapper}>
                <TextField
                    variant={"outlined"}
                    type={"password"}
                    value={password2}
                    onChange={handleChangePassword2}
                    label={"Confirmation du mot de passe"}
                    sx={styles.field}
                    error={!!errors.password2}
                    helperText={errors.password2} />
            </Grid>
            <Grid item>
                <LoadingButton isLoading={isLoading} type="submit" variant={"contained"} color={"primary"} sx={styles.submitButton}>
                    S'inscrire
                </LoadingButton>
            </Grid>
        </Grid>
    </form>;
};

export default Component;