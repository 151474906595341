import React from "react";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { useDrag } from 'react-dnd'

import Typography from "@mui/material/Typography";
import Widget from "./Widget";
import PanToolIcon from "@mui/icons-material/PanTool";

const Component = ({ widget, cellSize, defaultWidgetSize }) => {
    const gridMargin = 30;
    const padding = 20;

    const defaultSizeX = 4;
    const defaultSizeY = 4;

    const width = ((cellSize + gridMargin) * defaultSizeX) - gridMargin - (padding * 2);
    const height = ((cellSize + gridMargin) * defaultSizeY) - gridMargin - (padding * 2);

    const onDragStart = e => {
        e.dataTransfer.setData("text/plain", "");
        e.dataTransfer.setData('dragData', JSON.stringify(widget));
    }

    return <li
        className="droppable-element"
        draggable={true}
        unselectable="on"
        // data-grid={{h:3, w:3}}
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        onDragStart={onDragStart}
        style={{marginBottom: 20}}
    >
        <Grid key={widget.id} item style={{width: width + (padding * 2), height: height + (padding * 2)}}>
            <Paper style={{width: '100%', height: '100%', padding: padding, boxSizing: "border-box"}}>
                <Typography style={{ fontSize: "0.8em"}} width={width}>{widget.name}</Typography>
            </Paper>
        </Grid>
    </li>
};
/* <Widget widget={widget} width={width} height={height} /> */

export default Component;